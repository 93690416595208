import React from "react";
import SignUp from "../components/registerPage/SignUp";

const RegisterPage = () => {
  return (
    <>
      <SignUp />
    </>
  );
};

export default RegisterPage;
